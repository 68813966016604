body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  /*background-color: #dddddd !important;*/
  background-color: #F9F9F9 !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
}

.link {
  color: #CB8F2A;
  text-decoration: none;
  background-color: transparent;
}

.link:hover {
  color: #CB8F2A;
  text-decoration: underline;
  background-color: transparent;
}

.card-header {
  border: none;
}

.title {
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 20px;
}

.label {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #5E5E5E;
  letter-spacing: 0;
}

th {
  background: #1D5F79;
  color:  #FFFFFF;
  border: none !important;
}

td {
  border: none !important;
}

ul.no-bullet {
  list-style-type: none;
}

.btn-primary {
  background-color: #1D5F79 !important;
  border: none !important;
}
.btn-primary:hover {
  background-color: #CB8F2A !important;
  border: none !important;
  opacity: 0.8;
}

.emerald  {
  color: #1D5F79;
}

h1,h2,h3,h5, ul li {
  color: #212529 !important;
}

input[type="checkbox"] {
  accent-color: #1D5F79 !important;
}
.basic-navbar-nav {
    margin-bottom: 0px;
}

.basic-nav-dropdown>a.nav-link {
    color: #1E202C !important;
    margin-top: 0px;
    margin-bottom: 0px;
}

.basic-nav-dropdown {
    padding-right: 30px !important;
    padding-left: 30px !important;
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    height: 80px;
}

.basic-nav-dropdown:hover {
    border-bottom: 8px solid #CB8F2A;
}

.dropdown-menu {
    margin-top: 8px !important;
    right: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.dropdown-toggle::after {
    display:none !important;
}

.dropdown-header {
    color: #1E202C;
}

#nav-top {
    height: 50px !important;
    background: #EEEEEE;
} 

#basic-navbar-nav {
    height: 80px;
}

#logo {
    height: 20px;
}

.dropdown-item {
    font-size: 14px !important;
}

.dropdown-item:active {
    background: #CB8F2A !important;
}

a.dropdown-toggle.nav-link {
    letter-spacing: 3.82px;
}

.dropdown:hover>.dropdown-menu {
    display: block;
}

#nav-bot {
    background-color: #FFFFFF !important;
}

.nav-text {
    color: #797979 !important;
}

onepointTM {
    position: relative;
    font-size: 60%;
    line-height: 0;
    vertical-align: baseline;
    bottom: 6px !important;
}
.emerald  {
    color: #1D5F79;
}

.link {
    color: #CB8F2A;
    text-decoration: none;
    background-color: transparent;
}

.link:hover {
    color: #CB8F2A;
    text-decoration: underline;
    background-color: transparent;
}

.reg-text {
    color: #212529;
    margin-top: 10px;
    margin-bottom: 10px;
}

.reg-text-bold {
    color: #212529;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}

h1,h2,h3,h5, ul li {
    color: #212529 !important;
}

.p-3 {
    padding: 0.5rem!important;
    color: #212529 !important;
}

input[type="checkbox"] {
    accent-color: #1D5F79 !important;
}

onepointTM {
    position: relative;
    font-size: 60%;
    line-height: 0;
    vertical-align: baseline;
    bottom: 6px !important;
}
#user-info {
    padding: 0;
    list-style: none;
}

.progress-bar {
    background: #1D5F79 !important;
}

.card-header-account {
    background: #AAAAAA !important ;
    font: 16px;
}

.password-validation {
    margin-bottom: 0 !important;
}

.red {
    color: red;
}

.green {
    color: green;
}

h2.font-weight-bold{
    font-family: 'OpenSans-Semibold' !important;
    font-size: 36px;
    color: #000000;
    letter-spacing: 0px;
    padding-left: 1.25rem;
}
.input {
  background: #F8F8F8 ;
  border: none;
  border-radius: 0;
  font-size: 14px !important;
  font-family: 'Raleway', sans-serif !important;
}

.prepend {
    background: #F8F8F8 ;
    border: none;
    border-radius: 0;
    font-size: 14px !important;
}

#password-strength-meter-progress {
  top:10px;
  right:0;
  position: absolute;
  z-index:10;
}

#password-strength-meter-label {
  top:10px;
  right:25px;
  position: absolute;
  z-index:20;
}

.error {
  background: #5E5E5E;
}


.password-strength-meter {
    text-align: left;
}

.password-strength-meter-progress {

    -webkit-appearance: none;

            appearance: none;
    width: 100px;
    height: 20px;
}

.password-strength-meter-progress::-webkit-progress-bar {
    /*background-color: #1D5F79; */
    border-radius: 3px;
}

.password-strength-meter-label {
    font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}


.strength-Weak {
    background-color: #d6244f;
}

.strength-Fair {
    background-color: #CB8F2A;
}

.strength-Good {
    background-color: #1D5F79;
}

.strength-Strong {
    background-color: #70C1B3;
}


img.card-img {
    width: 40%;
}

.card-header-register {
    background: #F9F9F9 !important;
}

.sub-heading {
    color: #000000 !important;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
}

#date-label-1 {
  padding-right: 15px;
}

#date-picker-1 {
  padding-left: 12px;
  padding-right: 0px;
}

#date-label-2 {
  padding-left: 0;
}

#date-picker-2 {
  padding-left: 0;
}

.order-info-back-color {
  background: #F2F2F2 !important ;
}
.order-items-back-blue {
  background: #2F59A1 !important ;
}
.white-text {
  color: #FFFFFF !important ;
}
.marging-bottom{
  margin-bottom: 20px;
  padding: 10px;
}
.items-padding{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.items-max-width{
  max-width: 1140px;
}

.text-bold{
  font-weight: bold;
}
.margin-top{
  margin-top: -50px;
}
.center-middle td{
  vertical-align: middle;
}

.btn-sort {
  padding: 0;
}

.btn-sort:hover {
  background: transparent !important;
  text-decoration: none !important;
}

.page-item.active .page-link {
  background: #1D5F79 !important;
}

.page-item.active .page-link:hover {
  background: #CB8F2A !important;
}


.page-link {
  border: none;
  font-size: 13px;
  color: black;
  border-radius: 50%;
}

input::-webkit-input-placeholder{
  color: #DDDDDD !important;
}

input::placeholder{
  color: #DDDDDD !important;
}

.card-accordion{
  padding: 2.0rem;
}

.modal {
  /*
  position: relative;
  max-width: 25rem;
   */
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
}

.dialog {
  width: 69rem;
  padding: 1rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  position: relative;
}

.dialog::-webkit-backdrop {
  background: hsl(0 0% 0% / 50%);
}

.dialog::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.dialog-close-btn {
  font-size: .75em;
  position: absolute;
  top: .25em;
  right: .25em;
}

.addressFormHeader {
  font-size: 22px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 1px solid #cbd3da;
  margin-top: 1px;
}

.dialog-card {
  padding: 20px;
  border:none;
}

.orderPoNumber {
  float:left;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 70%;
}

.cancelOrderLinks {
  float:right;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.cancelOrderLinks a {
  color: #ffffff;
}

.closeModal {
  float: right;
  margin-top: -50px;
  cursor: pointer;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: 14px;
  margin-right: 10px;
}

.closeModal:hover {
  color: red;
}

#cancel-item-div-body {
  margin-left: 33px;
}

.itemCancelRow {
  float: left;
  padding: 10px;
  width: 100%
}

.itemCell {
  width: 186px;
  height: 238px;
  float: left;
  margin-bottom: 5px;
  padding: 5px;
}

.itemImageCell {
  float: left;
  width: 100%;
  height: 110px;
}

.itemCheckBoxCell {
  float: left;
  margin-top: 20%;
  margin-right: 10px;
  margin-left: -30px;
}

.itemMsgCell {
  float: left;
}

.returnItemMsg {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  width: 100%;
  clear:both;
}

.imgContainer {
  height: 110px;
  width: 110px;
  overflow: hidden;
  border: 1px solid #DDDDDD;
  margin-left: 5px;
  margin-top: 5px;
  padding: 4px;
}

.imgContainer img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.cancel-order-div-body {
  margin-left: 47px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
}

.check-all-item {
  position: relative;
  margin-top: 5px;
  margin-left: -46px;
  text-align: left;
}

.input-item-cancel-qty {
  margin-top: 7px;
  margin-left: 5px;
  margin-bottom: 2px;
  float: left;
  width: 100%;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.total-qty-input {
  width: 35px;
  height: 19px;
  border-bottom: 1px solid #0b2e13;
  border-top:none;
  border-right: none;
  border-left: none;
  background-color: rgb(255, 255, 255);
  padding: 6px 1px 0 0;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 0;
}

.total-qty {
  float: left;
  margin-left: 3px;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 3px;
}

.error-msg {
  float:left;
  width: 100%;
  color: red;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  margin-top: 2px;
}

.cancel-spinner {
  width: 22px;
  height: 22px;
  margin-left: 20px;
}

/* Beginning */
.border-beginning {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-left: 1px solid #9fcdff;
}

/* Middle */
.border-middle {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
}

.border-middle-left-dot {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-left: 1px dotted #9fcdff;
}

.border-middle-right-dot {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-right: 1px dotted #9fcdff;
}


/** Last **/
.border-last-mgr-45 {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-right: 1px solid #9fcdff;
  margin-right: 45px;
}

.border-left-dot {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-left: 1px dotted #9fcdff;
  border-right: 1px solid #9fcdff;
}


.border-right-dot {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-left: 1px solid #9fcdff;
  border-right: 1px dotted #9fcdff;
}

.link {
  color: #CB8F2A;
  text-decoration: none;
  background-color: transparent;
}

link:hover {
  color: #CB8F2A;
  text-decoration: underline;
  background-color: transparent;
}


input::-webkit-input-placeholder{
    color: #DDDDDD !important;
}
input::placeholder{
    color: #DDDDDD !important;
}
.sm-img {
    vertical-align: middle;
    width: 40px;
}

.inline-text {
    vertical-align: middle;
    display: inline-block;
}



.code-list li{

    line-height: 30px;

}
.token-btn {
    color: black;
    background: transparent !important;
    display: inline-block;
}

.token-btn:hover {
    color: grey;
}

.margin-api-sample {
    margin-bottom: 24px;
}

.padding-body-api-sample {
    margin-bottom: 14px;
}

.btn-api-sample {
    margin-top: 97px;
}

.alert-error {
    background: #5E5E5E;
    color: white;
}


.reponse-card{
    height: 890px;
}
.active {
    color:#2E59A0 !important;
}

.tab {
    color: black !important;
}

.activeDoc {
    color:#CB8F2A !important;
    /*font-size: 16px;*/
    font-size: 17px;
    font-family: 'OpenSans-Semibold';
    /*font-weight: bold;*/ 
    font-weight: bolder;
}

.tabDoc {
    color: black !important;
    font-size: 16px;
    font-family: 'OpenSans';
}

#col-left {
    border-right: solid #DDDDDD 1px;
}

ul.nav {
    list-style: none;
}

ul.nav .flex-column{
    color: black !important;
    font-size: 16px;
    font-family: 'OpenSans';
}    

.width100{
    width: 100px;
}

.errCodes {
    color: #DC143C;
}

onepointTM {
    position: relative;
    font-size: 60%;
    line-height: 0;
    vertical-align: baseline;
    bottom: 6px !important;
}
.wrapper {
    display: inline-block;
    cursor: 'not-allowed';
  }
  
.btn-disabled {
  pointer-events: none;
}

.scroll{
  max-height: 300px; 
  overflow-y: auto;
}

.title-header {
    color: #DC143C;
    font-size: 14px;
}

onepointTM {
    position: relative;
    font-size: 60%;
    line-height: 0;
    vertical-align: baseline;
    bottom: 6px !important;
}
.col-border-right {
    border-right: 1px solid #DDDDDD;
}

.border-bottom {
    border-bottom: 1px solid #DDDDDD;
}

.no-padding {
    padding: 0 15px;
}

.background-dark {
    background: #333644;
}

.card-header-response {
    background: #000000;
    color: #FFFFFF;
}

.card-body-response {
    background: #1E202C;
    color: #F06292;
    position: relative;
    padding: 0px;
}

.card-response {
    border: 1px solid #5E5E5E;
}

.title-response {
    color: #AAAAAA;
}

.nav-item-circle {
    border: none;
    font-size: 12px;
    border-radius: 100%;
    background: #AAAAAA;
}


.nav-item-circle a:hover {
    border: none;
    font-size: 12px;
    border-radius: 100%;
    background: #CB8F2A;
}

.active-link {
    background: #1D5F79;
    border-radius: 100%;
}

.nav-item-circle-disabled {
    opacity: 0.3;
    border: none;
    font-size: 12px;
    border-radius: 100%;
    background: #8D8D8D;
}

.request-url {
    background: #F3F3F3;
    border: 1px solid #DDDDDD;
}

.nav-link-steps {
    color: #CB8F2A !important;
    font-family: OpenSans-Semibold;
    font-size: 16px;
    letter-spacing: 0;
}

.nav-link-steps:hover {
    text-decoration: underline;
}

.nav-link-steps.active {
    background: transparent !important;
    background: #CB8F2A;
}

.btn-clipboard {
    opacity: 0.3;
    position: absolute;
    top: 45px;
    right: 0;
    margin: 10px;
    z-index:2;
    background: #AAAAAA !important;
}

.btn-expand {
    opacity: 0.3;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    z-index:2;
    background: #333644 !important;
}
.btn-clipboard:hover {
    opacity: 1;
}

.btn-expand:hover {
    opacity: 1;
}

.ace_content {
    background: #1E202C;
}

.child {
    margin-left: 30px;
    color: black !important;
}

.incompleted {
    opacity: 0.3;
}

.modal-body-editor {
    padding:0;
}

.btn-hide {
    display: none;
}

.modal-header-editor > button {
    color: white;
}

.modal-header-editor > button:hover {
    color: #AAAAAA;
}

.modal-header-editor {
    background: #000000;
    color: white;
    border: none;
}

.table-header-balck {
    background: #1E202C;
    color: white;
    text-align: center;
}

.color-red{
    color: #FF342D;
}

.color-blue{
    color: #2F59A1;
}

.center-align{
    text-align: center;
}

.spinn-container{
    padding-top: 200px;
    text-align: center;
}

.width100{
    width: 100px;
}

.red{
    color: red;    
}

div.nav-item a.active{
    font-weight: bold;
}

div.nav-item a.menu-header{    
    text-decoration: none;
    pointer-events: none;
}



.red a{
    color: red;
}


.button-right{
    text-align: right;
}

.wrapper {
    display: inline-block;
    cursor: 'not-allowed';
  }
  

.btn-disabled { 
  pointer-events: none;
}

.input-comment {
  width: 50%;
}

.support-text {
  font-size: 13px !important;
}
input::-webkit-input-placeholder{
    color: #DDDDDD !important;
}
input::placeholder{
    color: #DDDDDD !important;
}

#date-label-1 {
  padding-right: 15px;
}

#date-picker-1 {
  padding-left: 12px;
  padding-right: 0px;
}

#date-label-2 {
  padding-left: 0;
}

#date-picker-2 {
  padding-left: 0;
}

.order-info-back-color {
  background: #F2F2F2 !important ;
}
.order-items-back-blue {
  background: #2F59A1 !important ;
}
.white-text {
  color: #FFFFFF !important ;
}
.marging-bottom{
  margin-bottom: 20px;
  padding: 10px;
}
.items-padding{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.items-max-width{
  max-width: 1140px;
}

.text-bold{
  font-weight: bold;
}
.margin-top{
  margin-top: -50px;
}
.center-middle td{
  vertical-align: middle;
}

.btn-sort {
  padding: 0;
}

.btn-sort:hover {
  background: transparent !important;
  text-decoration: none !important;
}

.page-item.active .page-link {
  background: #1D5F79 !important;
}

.page-item.active .page-link:hover {
  background: #CB8F2A !important;
}


.page-link {
  border: none;
  font-size: 13px;
  color: black;
  border-radius: 50%;
}

input::placeholder{
  color: #DDDDDD !important;
}

.card-accordion{
  padding: 2.0rem;
}

.modal {
  /*
  position: relative;
  max-width: 25rem;
   */
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
}

.dialog {
  width: 69rem;
  padding: 1rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  position: relative;
}

.dialog::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.dialog-close-btn {
  font-size: .75em;
  position: absolute;
  top: .25em;
  right: .25em;
}

.addressFormHeader {
  font-size: 22px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 1px solid #cbd3da;
  margin-top: 1px;
}

.dialog-card {
  padding: 20px;
  border:none;
}

.orderPoNumber {
  float:left;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 70%;
}

.cancelOrderLinks {
  float:right;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.cancelOrderLinks a {
  color: #ffffff;
}

.closeModal {
  float: right;
  margin-top: -50px;
  cursor: pointer;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: 14px;
  margin-right: 10px;
}

.closeModal:hover {
  color: red;
}

#cancel-item-div-body {
  margin-left: 33px;
}

.itemCancelRow {
  float: left;
  padding: 10px;
  width: 100%
}

.itemCell {
  width: 186px;
  height: 238px;
  float: left;
  margin-bottom: 5px;
  padding: 5px;
}

.itemImageCell {
  float: left;
  width: 100%;
  height: 110px;
}

.itemCheckBoxCell {
  float: left;
  margin-top: 20%;
  margin-right: 10px;
  margin-left: -30px;
}

.itemMsgCell {
  float: left;
}

.returnItemMsg {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  width: 100%;
  clear:both;
}

.imgContainer {
  height: 110px;
  width: 110px;
  overflow: hidden;
  border: 1px solid #DDDDDD;
  margin-left: 5px;
  margin-top: 5px;
  padding: 4px;
}

.imgContainer img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.cancel-order-div-body {
  margin-left: 47px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
}

.check-all-item {
  position: relative;
  margin-top: 5px;
  margin-left: -46px;
  text-align: left;
}

.input-item-cancel-qty {
  margin-top: 7px;
  margin-left: 5px;
  margin-bottom: 2px;
  float: left;
  width: 100%;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.total-qty-input {
  width: 35px;
  height: 19px;
  border-bottom: 1px solid #0b2e13;
  border-top:none;
  border-right: none;
  border-left: none;
  background-color: rgb(255, 255, 255);
  padding: 6px 1px 0 0;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 0;
}

.total-qty {
  float: left;
  margin-left: 3px;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 3px;
}

.error-msg {
  float:left;
  width: 100%;
  color: red;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  margin-top: 2px;
}

.cancel-spinner {
  width: 22px;
  height: 22px;
  margin-left: 20px;
}

/* Beginning */
.border-beginning {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-left: 1px solid #9fcdff;
}

/* Middle */
.border-middle {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
}

.border-middle-left-dot {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-left: 1px dotted #9fcdff;
}

.border-middle-right-dot {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-right: 1px dotted #9fcdff;
}


/** Last **/
.border-last-mgr-45 {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-right: 1px solid #9fcdff;
  margin-right: 45px;
}

.border-left-dot {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-left: 1px dotted #9fcdff;
  border-right: 1px solid #9fcdff;
}


.border-right-dot {
  border-top:1px solid #9fcdff;
  border-bottom: 1px solid #9fcdff;
  border-left: 1px solid #9fcdff;
  border-right: 1px dotted #9fcdff;
}

.link {
  color: #CB8F2A;
  text-decoration: none;
  background-color: transparent;
}

link:hover {
  color: #CB8F2A;
  text-decoration: underline;
  background-color: transparent;
}


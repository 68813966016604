.active {
    color:#2E59A0 !important;
}

.tab {
    color: black !important;
}

.activeDoc {
    color:#CB8F2A !important;
    /*font-size: 16px;*/
    font-size: 17px;
    font-family: 'OpenSans-Semibold';
    /*font-weight: bold;*/ 
    font-weight: bolder;
}

.tabDoc {
    color: black !important;
    font-size: 16px;
    font-family: 'OpenSans';
}

#col-left {
    border-right: solid #DDDDDD 1px;
}

ul.nav {
    list-style: none;
}

ul.nav .flex-column{
    color: black !important;
    font-size: 16px;
    font-family: 'OpenSans';
}    

.width100{
    width: 100px;
}

.errCodes {
    color: #DC143C;
}

onepointTM {
    position: relative;
    font-size: 60%;
    line-height: 0;
    vertical-align: baseline;
    bottom: 6px !important;
}
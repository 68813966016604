.wrapper {
    display: inline-block;
    cursor: 'not-allowed';
  }
  
.btn-disabled {
  pointer-events: none;
}

.scroll{
  max-height: 300px; 
  overflow-y: auto;
}

.title-header {
    color: #DC143C;
    font-size: 14px;
}

onepointTM {
    position: relative;
    font-size: 60%;
    line-height: 0;
    vertical-align: baseline;
    bottom: 6px !important;
}
.password-strength-meter {
    text-align: left;
}

.password-strength-meter-progress {

    appearance: none;
    width: 100px;
    height: 20px;
}

.password-strength-meter-progress::-webkit-progress-bar {
    /*background-color: #1D5F79; */
    border-radius: 3px;
}

.password-strength-meter-label {
    font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}


.strength-Weak {
    background-color: #d6244f;
}

.strength-Fair {
    background-color: #CB8F2A;
}

.strength-Good {
    background-color: #1D5F79;
}

.strength-Strong {
    background-color: #70C1B3;
}


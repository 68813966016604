body {
  /*background-color: #dddddd !important;*/
  background-color: #F9F9F9 !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
}

.link {
  color: #CB8F2A;
  text-decoration: none;
  background-color: transparent;
}

.link:hover {
  color: #CB8F2A;
  text-decoration: underline;
  background-color: transparent;
}

.card-header {
  border: none;
}

.title {
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 20px;
}

.label {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #5E5E5E;
  letter-spacing: 0;
}

th {
  background: #1D5F79;
  color:  #FFFFFF;
  border: none !important;
}

td {
  border: none !important;
}

ul.no-bullet {
  list-style-type: none;
}

.btn-primary {
  background-color: #1D5F79 !important;
  border: none !important;
}
.btn-primary:hover {
  background-color: #CB8F2A !important;
  border: none !important;
  opacity: 0.8;
}

.emerald  {
  color: #1D5F79;
}

h1,h2,h3,h5, ul li {
  color: #212529 !important;
}

input[type="checkbox"] {
  accent-color: #1D5F79 !important;
}
.emerald  {
    color: #1D5F79;
}

.link {
    color: #CB8F2A;
    text-decoration: none;
    background-color: transparent;
}

.link:hover {
    color: #CB8F2A;
    text-decoration: underline;
    background-color: transparent;
}

.reg-text {
    color: #212529;
    margin-top: 10px;
    margin-bottom: 10px;
}

.reg-text-bold {
    color: #212529;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}

h1,h2,h3,h5, ul li {
    color: #212529 !important;
}

.p-3 {
    padding: 0.5rem!important;
    color: #212529 !important;
}

input[type="checkbox"] {
    accent-color: #1D5F79 !important;
}

onepointTM {
    position: relative;
    font-size: 60%;
    line-height: 0;
    vertical-align: baseline;
    bottom: 6px !important;
}